<script setup lang="ts">
import { useRoute } from 'vue-router';
import { ref, watch } from 'vue';
import { useGlobalComponentsStore } from '@/stores/global';
import { useLoginStore } from '@/stores/loginStore';
import MainMenu from '@/components/general/MainMenu.vue';
import Loading from '@/components/general/Loading.vue';
import ConfirmDialog from "@/components/general/ConfirmDialog.vue";
import { useConfirmDialog } from "@/composables/useConfirmDialog";
import { isBiggerFontSize, isContrastColor, setBiggerFontSize, setContrastColor } from "@/helpers/accessibility";

const globalComponentsStore = useGlobalComponentsStore();
const currentRoute = useRoute();
const storeLogin = useLoginStore();
const confirmDialog = useConfirmDialog();
watch(
    () => globalComponentsStore.text,
    () => {
        globalComponentsStore.toggleShowSnackbar();
    }
);

if (isBiggerFontSize()) {
    setBiggerFontSize(true)
}

if (isContrastColor()) {
    setContrastColor(true);
}

</script>

<template>
    <v-snackbar
        v-model="globalComponentsStore.showSnackbar"
        :timeout="globalComponentsStore.timeout"
        :class="globalComponentsStore.class"
        position="fixed"
        location="top"
        min-width="0"
    >
        <pre>{{ globalComponentsStore.text }}</pre>
        <template v-slot:actions>
            <v-btn
                variant="text"
                @click="globalComponentsStore.hideSnackbar"
                class="mr-2"
            >
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </template>
    </v-snackbar>
    <Loading v-if="globalComponentsStore.showLoading"/>
    <v-layout>
        <MainMenu
            v-if="storeLogin.getIsAuthenticated"
            :key="currentRoute.fullPath.split('?')[0]"
        />
        <v-main>
            <RouterView
                :key="currentRoute.fullPath.split('?')[0]"
            />
        </v-main>
    </v-layout>
    <ConfirmDialog
        @confirm="confirmDialog.onConfirm"
    />
</template>
<style lang="scss">
@import '@/assets/css/main.scss';

html {
    overflow-y: auto !important;
}

.notification-success {
    & .v-overlay__content {
        background-color: var(--color-succes);
    }
}

.notification-warning {
    & .v-overlay__content {
        background-color: var(--color-warning);
    }
}

.notification-error {
    & .v-overlay__content {
        background-color: var(--color-danger);
    }
}

.bottom {
    display: flex;
    flex-flow: column nowrap;
}

.bottom .scrollbar-table {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
}

//TODO: TOTO ODSTRANIT PO AKTUALIZACI VUETIFY
.v-autocomplete .v-field__append-inner i {
    cursor: pointer;
}

.main-content {
    max-width: 100%;
}

.top-wrapper {
    @include display-flex(row, nowrap, flex-start, stretch);
    min-height: 100%;

    @media (max-width: 600px) {
        flex-flow: column;
    }

    .left,
    .right {
        width: 50%;
        //border: 1px solid red

        @media (max-width: 600px) {
            width: 100%;
        }
    }

    .full {
        width: 100%;
    }

    .left {
        padding-right: 16px;

        @media (max-width: 600px) {
            padding-right: 0;
        }
    }

    .right {
        padding: 16px;
        background-color: var(--blue-light);
        @include display-flex(column, nowrap, initial, initial);
    }
}

.font-size-html,
.v-field {
    font-size: 1rem !important;
}

.new {
    background-color: transparent !important;
    box-shadow: none !important;

    & .icon {
        margin-right: 8px;
    }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance: textfield;
}

.bg-blue-light {
    background-color: var(--blue-light);
}

html.v-overlay-scroll-blocked {
    position: static !important;

    body:not([data-pdfjsprinting="true"]) {
        position: fixed;
        top: var(--v-body-scroll-y);
        left: var(--v-body-scroll-x);
        width: 100%;
        height: 100%;
    }
}

.v-list-item--density-default:not(.responsive-menu-link).v-list-item--one-line {
    min-height: 33px !important;
    padding-top: 0!important;
    padding-bottom: 0!important;
}

.v-list-item--density-default:not(.v-list-item--nav):not(.permission):not(.responsive-menu-link).v-list-item--one-line {
    padding-inline: 10px !important;
}

.main-content {
    .global-actions-menu {
        @include display-flex(row, nowrap, flex-start, center);
        column-gap: 10px;
    }
    .middle {
        @include display-flex(row, nowrap, space-between, flex-start);
        width: 100%;
        & .selector {
            cursor: pointer;
            color: var(--grey-dark);
            font-weight: var(--font-weight-700);
            & i {
                margin-left: 5px;
                position: relative;
                top: 2px;
            }
        }

        @media (max-width: 600px) {
            flex-direction: column;
            align-items: start;
            & .selector {
                margin-bottom: 10px;
            }
        }
    }
}
</style>

<style scoped lang="scss">
@import '@/assets/css/main.scss';

:deep(.v-main) {
    @include display-flex(row, nowrap, flex-start, stretch);
    flex-grow: 1;
}

pre {
    font-family: var(--font-family-text);
    text-overflow: ellipsis;
    overflow: hidden;
}

:deep(.v-snackbar__content) {
    max-width: calc(100% - 42px);
}
</style>
