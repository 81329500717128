import { RouteNamesEnum } from '@/enums/RouteNamesEnum';
import { getGenericModulePermissionsList } from '@/composables/usePermissions';
import { ModulesEnum } from '@/enums/ModulesEnum';

const ProjectsRoutes = [
    {
        path: '/projects',
        component: () => import('../components/general/RootModule.vue'),
        meta: {
            title: 'ProjectsModule.name',
            icon: 'mdi-hammer-wrench',
            auth: {
                required: true,
            },
            showInMenu: true,
            moduleRoot: true,
        },
        children: [
            {
                path: ':id(\\d+|new)?',
                name: RouteNamesEnum.Projects,
                component: () => import('../views/projects-module/ProjectsView.vue'),
                meta: {
                    title: 'ProjectsModule.name',
                    auth: {
                        required: true,
                    },
                    canAny: getGenericModulePermissionsList(ModulesEnum.Projects),
                    showInMenu: true,
                    defaultFilters: [
                        { f: 'archived_at', o: 'eq', v: null, visible: false },
                    ]
                },
            },
            {
                path: 'archive/:id(\\d+|new)?',
                name: RouteNamesEnum.ProjectsArchive,
                component: () => import('../views/projects-module/ProjectsView.vue'),
                meta: {
                    title: 'ProjectsModule.archive',
                    auth: {
                        required: true,
                    },
                    canAny: getGenericModulePermissionsList(ModulesEnum.Projects),
                    showInMenu: true,
                    defaultFilters: [
                        { f: 'archived_at', o: 'neq', v: null, visible: false },
                    ]
                },
            },
            {
                path: 'long-term-plan',
                name: RouteNamesEnum.LongTermPlan,
                component: () => import('../views/projects-module/LongTermPlanView.vue'),
                meta: {
                    title: 'ProjectsModule.long-term-plan',
                    auth: {
                        required: true,
                    },
                    canAny: getGenericModulePermissionsList(ModulesEnum.Projects),
                    showInMenu: true,
                },
            },
            {
                path: '',
                component: () => import('../components/general/SubModule.vue'),
                meta: {
                    title: 'SettingsModule.name',
                    auth: {
                        required: true,
                    },
                    showInMenu: true,
                },
                children: [
                    {
                        path: 'categories/:id(\\d+|new)?',
                        name: RouteNamesEnum.ProjectCategories,
                        component: () => import('../views/projects-module/ProjectCategoriesView.vue'),
                        meta: {
                            title: 'ProjectsModule.project-categories',
                            auth: {
                                required: true,
                            },
                            canAny: getGenericModulePermissionsList(ModulesEnum.Projects),
                            showInMenu: true,
                        },
                    },
                    {
                        path: 'stock-requirements-list/:id(\\d+|new)?',
                        name: RouteNamesEnum.ProjectStockRequirementsListView,
                        component: () => import('../views/projects-module/ProjectStockRequirementsListView.vue'),
                        meta: {
                            title: 'ProjectsModule.project-stock-requirements-list',
                            auth: {
                                required: true,
                            },
                            canAny: getGenericModulePermissionsList(ModulesEnum.Projects),
                            showInMenu: true,
                        },
                    },
                ],
            }
        ],
    },
];

export default ProjectsRoutes;
