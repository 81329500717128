import { createRouter, createWebHistory } from 'vue-router';
import { i18n } from '@/main';
import { useLoginStore } from '@/stores/loginStore';
import AuthRoutes from '@/router/AuthRoutes';
import CarsRoutes from '@/router/CarsRoutes';
import StockRoutes from '@/router/StockRoutes';
import EmployeesRoutes from '@/router/EmployeesRoutes';
import SettingsRoutes from '@/router/SettingsRoutes';
import ClientsRoutes from '@/router/ClientsRoutes';
import { RouteNamesEnum } from "@/enums/RouteNamesEnum";
import ProjectsRoutes from "@/router/ProjectsRoutes";
import DocumentsRoutes from '@/router/DocumentsRoutes';
import HomeRoutes from '@/router/HomeRoutes';

const routes = [
    ...AuthRoutes,
    {
        path: '/gdpr',
        name: RouteNamesEnum.Gdpr,
        component: () => import('../views/general/GdprView.vue'),
        meta: {
            title: 'GdprView.title',
            auth: {
                required: false,
                roles: [],
            },
            showInMenu: false,
        },
    },
    ...HomeRoutes,
    {
        path: '/my-account',
        name: RouteNamesEnum.MyAccount,
        component: () => import('../views/general/MyAccountView.vue'),
        meta: {
            title: 'MenuComponent.my-account',
            auth: {
                required: true,
                roles: [],
            },
            showInMenu: false,
        },
    },
    ...ClientsRoutes,
    ...ProjectsRoutes,
    ...EmployeesRoutes,
    ...StockRoutes,
    ...CarsRoutes,
    ...DocumentsRoutes,
    {
        path: '/calendar',
        name: 'calendar',
        component: () => import('../views/general/HomeView.vue'),
        meta: {
            title: 'CalendarModule.name',
            icon: 'mdi-calendar-blank',
            auth: {
                required: false,
                roles: [],
            },
            showInMenu: false,
        },
        children: [],
    },
    ...SettingsRoutes,
    {
        path: '/unauthorized',
        name: 'unauthorized',
        component: () => import('../views/UnauthorizedView.vue'),
        meta: {
            title: 'Unauthorized.title',
            auth: {
                required: false,
            },
            showInMenu: false,
        },
    },
    {
        path: '/:pathMatch(.*)*',
        name: 'not-found',
        component: () => import('../views/NotFoundView.vue'),
        meta: {
            title: 'NotFoundView.title',
            auth: {
                required: false,
                roles: [],
            },
            showInMenu: false,
        },
    },
];

const router = createRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    routes,
});

router.beforeEach(async (to, from, next) => {
    //TODO: tohle mozna presunout nad to
    const storeLogin = useLoginStore();

    i18n.global.locale.value = storeLogin.userSession.language as 'cs' | 'en';
    document.title = (i18n.global.t(to.meta.title as string) +
        ' | IS NEWE') as string;

    //vyzaduje stranka prihlaseni
    if ((to.meta.auth as { required: boolean }).required) {
        //je prihlasen
        if (storeLogin.getIsAuthenticated) {
            //TODO: check user roles
            next();
        }
        //neni prihlasen
        else {
            //je to po page refresh
            if (storeLogin.getRefreshToken) {
                await storeLogin
                    .refreshCredentials(true)
                    .then(() => {
                        //TODO: tady bych mel v then mit nejakou response a kontrolovat 200, protoze ted dostanu 401 (mam nejakou blbost v refreshtoken a chci jit na home -> jsem tam)
                        //TODO: ve stylu (if success) next() else next("/") ; taky tady mozna budu volat logout
                        next();
                    })
                    .catch((error) => {
                        alert(error);
                        //TODO: tady taky nejspis logout
                        next('/');
                    });
            }
            //nebyl prihlasen ani pred tim
            else {
                next('/');
            }
        }
    }
    //stranka nevyzaduje prihlaseni
    else {
        //je prihlasen
        if (storeLogin.getIsAuthenticated) {
            //chce jit na login
            if (to.path === '/') {
                document.title = (i18n.global.t('HomeView.title' as string) +
                    ' | IS NEWE') as string;
                next('/home');
            }
            //chce jit jinam (treba gdpr)
            else {
                next();
            }
        }
        //neni prihlasen a stranka nevyzaduje prihlaseni
        else {
            //ale byl prihlasen -> prisel po refresh
            if (storeLogin.getRefreshToken) {
                //chce jit na login
                if (to.path === '/') {
                    await storeLogin
                        .refreshCredentials(true)
                        .then(() => {
                            document.title = (i18n.global.t(
                                'HomeView.title' as string
                            ) + ' | IS NEWE') as string;
                            next('/home');
                        })
                        .catch((error) => {
                            alert(error);
                            next();
                        });
                }
                //nechce na login
                else {
                    storeLogin.refreshCredentials();
                    next();
                }
            }
            //nebyl prihlasen ani pred tim
            else {
                next();
            }
        }
    }
});

export default router;
