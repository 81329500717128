<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import { useLoginStore } from '@/stores/loginStore';
import { type RouteRecordRaw, useRouter } from 'vue-router';
import { RouteNamesEnum } from "@/enums/RouteNamesEnum";
import { computed, ref } from 'vue';
import { useAcl } from "vue-simple-acl";

const { t } = useI18n();
const loginStore = useLoginStore();
const { anyCan } = useAcl();
const router = useRouter();
const drawer = ref(false);
const openedMenuItems = ref([]);

const hasActiveChild = (route: RouteRecordRaw): boolean => {
    if (route.name === router.currentRoute.value.name) {
        return true;
    }

    return route?.children?.some(
        (child: any) => hasActiveChild(child)
    ) ?? false;
};

const menuItems = computed(() => {
    const menu: RouteRecordRaw[] = [];
    for (const route of router.options.routes) {
        const newRoute = { ...route };

        if (newRoute?.meta?.showInMenu !== true) {
            continue;
        }

        if (newRoute?.meta?.moduleRoot === true) {
            if (newRoute?.children && newRoute.children.length > 0) {
                const children = newRoute.children.filter(
                    (child: any) => child.meta
                        && child.meta.showInMenu === true
                        && (
                            child.meta.canAny === undefined
                            || anyCan(child.meta.canAny)
                        )
                );

                const hasActiveChildren = children.some(
                    (child: any) => hasActiveChild(child)
                );

                if (children.length > 0) {
                    const firstChild = children.shift();
                    if (firstChild) {
                        newRoute.path = firstChild.path;
                        newRoute.name = firstChild.name;

                        for (const child of children) {
                            if (child.children && child.children?.length > 0) {
                                child.name = child.children[0].name;
                            }
                        }

                        newRoute.children = children;
                        newRoute.meta.active = hasActiveChildren;
                        if (newRoute.name !== undefined) {
                            menu.push(newRoute);
                        }
                        continue;
                    }
                }
            }
        }

        if (newRoute?.children && newRoute.children.length > 0) {
            newRoute.children = newRoute.children.filter(
                (child: any) => child.meta
                    && child.meta.showInMenu === true
                    && (
                        child.meta.canAny === undefined
                        || anyCan(child.meta.canAny)
                    )
            );
        }
        if (newRoute.name !== undefined) {
            menu.push(newRoute);
        }
    }
    return menu;
});

const responsiveMenuItems = computed(() => {
    const menu: RouteRecordRaw[] = [];
    for (const route of router.options.routes) {
        const newRoute = { ...route };

        if (newRoute?.meta?.showInMenu !== true) {
            continue;
        }

        if (newRoute?.children && newRoute.children.length > 0) {
             const children = newRoute.children.filter(
                (child: any) => child.meta
                    && (
                        child?.meta?.showInMenu === true
                    )
                    && (
                        child?.meta?.canAny === undefined
                        || anyCan(child.meta.canAny)
                    )
            );

            for (const child of children) {
                if (child.children && child.children?.length > 0) {
                    child.name = child.children[0].name;
                }
            }

            newRoute.children = children;
        }
        if (newRoute.children?.length === 1) {
            newRoute.path = newRoute.children[0].path;
            newRoute.name = newRoute.children[0].name;
            newRoute.children = [];
        }
        menu.push(newRoute);
    }
    return menu;
});

const subMenuItems = (routes: Array<RouteRecordRaw>): Array<RouteRecordRaw> => {
    return routes.filter(
        (route) => route.meta
            && route.meta.showInMenu === true
            && (
                route.meta.canAny === undefined
                || anyCan(route?.meta?.canAny as string ?? '')
            )
    );
};
const logout = async () => {
    await loginStore.logout();
};
</script>

<template>
    <v-app-bar
        prominent
        absolute
        :height="61"
        class="py-0"
    >
        <router-link
            :to="{ name: RouteNamesEnum.Home }"
            class="logo"
        >
            <img
                src="@/assets/img/icons/newe-logo.svg"
                alt="Logo"
            />
        </router-link>
        <template v-if="!$vuetify.display.smAndDown">
            <nav>
                <router-link
                    v-for="(route, key) in menuItems"
                    :to="{ name: route.name }"
                    :key="key"
                    class="module"
                    :class="{ 'router-link-active': route.meta?.active }"
                >
                    <v-menu
                        content-class="main-menu-submenu rounded-0"
                        open-on-hover
                        v-if="route.children && subMenuItems(
                            route.children
                        ).length > 0"
                        :transition="false"
                    >
                        <template #activator="{ props }">
                            <p v-bind="props">
                                <i
                                    :class="'mdi ' + (route.meta?.icon as string)"
                                    class="mr-2"
                                ></i>
                                {{ t(route.meta?.title as string) }}
                            </p>
                        </template>
                        <v-list>
                            <v-list-item
                                v-for="(subroute, subKey) in route.children"
                                :key="subKey"
                                :to="{name: subroute.name}"
                            >
                                {{ t(subroute.meta?.title as string) }}
                            </v-list-item>
                        </v-list>
                    </v-menu>
                    <p v-else>
                        <i
                            :class="'mdi ' + (route.meta?.icon as string)"
                            class="mr-2"
                        ></i>
                        {{ t(route.meta?.title as string) }}
                    </p>
                </router-link>
            </nav>
            <div class="other-links">
                <router-link :to="{name: RouteNamesEnum.Settings}">
                    <img
                        src="@/assets/img/icons/menu-icons/settings.svg"
                        :alt="t('MenuComponent.settings')"
                        class="menu-link-icon"
                    />
                </router-link>
            </div>
        </template>
        <v-app-bar-nav-icon
            v-else
            variant="text"
            @click.stop="drawer = !drawer"
        ></v-app-bar-nav-icon>
        <v-menu
            :close-on-content-click="false"
            location="bottom"
            content-class="avatar-dropdown-conent"
        >
            <template #activator="{ props }">
                <v-card
                    v-bind="props"
                    class="avatar-menu"
                >
                    <v-avatar color="primary">
                        <span class="text-h6">{{loginStore.getUserInitials}}</span>
                    </v-avatar>
                </v-card>
            </template>
            <v-list class="avatar-dropdown">
                <v-list-item
                    :title="loginStore.getUserFullName"
                    :subtitle="loginStore.getUser?.username"
                />
                <router-link
                    :to="{ name: RouteNamesEnum.MyAccount }"
                    class="link-wrapper"
                >
                    <v-list-item>
                        <v-icon
                            icon="mdi-account"
                            size="small"
                        ></v-icon>
                        {{ t('MenuComponent.my-account') }}
                    </v-list-item>
                </router-link>
                <v-list-item @click="logout">
                    <v-icon
                        icon="mdi-logout-variant"
                        size="small"
                    ></v-icon>
                    {{ t('MenuComponent.logout') }}
                </v-list-item>
            </v-list>
        </v-menu>
    </v-app-bar>
    <v-navigation-drawer
        v-model="drawer"
        location="right"
        temporary
        class="responsive-menu"
    >
        <v-list v-model:opened="openedMenuItems">
            <template v-for="route in responsiveMenuItems">
                <v-list-group
                    v-if="route.children && subMenuItems(
                            route.children
                        ).length > 0"
                    :key="route.meta?.title + '-group'"
                    :value="route.meta?.title"
                >
                    <template #activator="{ props }">
                        <v-list-item
                            v-bind="props"
                            :prepend-icon="'mdi ' + (route.meta?.icon as string)"
                            :title="t(route.meta?.title as string)"
                            class="responsive-menu-link"
                        >
                        </v-list-item>
                    </template>


                    <v-list-item
                        v-for="subRoute in route.children"
                        :key="subRoute.meta?.title + '-responsive'"
                        :title="t(subRoute.meta?.title as string)"
                        :value="subRoute.name"
                        :to="{ name: subRoute.name }"
                        class="responsive-menu-link"
                    ></v-list-item>
                </v-list-group>
                <v-list-item
                    v-else
                    :key="route.meta?.title + '-item'"
                    :prepend-icon="'mdi ' + (route.meta?.icon as string)"
                    :title="t(route.meta?.title as string)"
                    :to="{ name: route.name }"
                    :value="route.meta?.title"
                    class="responsive-menu-link"
                ></v-list-item>
            </template>
            <v-list-item
                prepend-icon="mdi-cog-outline"
                :title="t('MenuComponent.settings')"
                :to="{ name: RouteNamesEnum.Settings }"
                class="responsive-menu-link"
                :value="RouteNamesEnum.Settings"
            ></v-list-item>
        </v-list>
    </v-navigation-drawer>
</template>

<style scoped lang="scss">
@import '@/assets/css/main.scss';

header {
    @include display-flex(row, nowrap, space-between, stretch);
    background-color: var(--white);
    color: var(--grey-dark);
    padding-left: 16px;
    padding-right: 16px;
    padding-block: 10px;
    border-bottom: 1px solid var(--grey-light);
    position: relative;
    width: 100%;
}

nav,
.other-links {
    @include display-flex(row, nowrap, flex-start, stretch);

    &:not(.responsive-menu) a {
        color: var(--grey-dark);
        text-decoration: none;
        font-weight: var(--font-weight-700);
        @include display-flex(row, nowrap, flex-start, center);
        padding: 0 16px;
        @include transition(all, 250ms, ease-in-out);

        &:hover,
        &.router-link-active {
            color: var(--blue);
            @include transition(all, 250ms, ease-in-out);
        }

        & i,
        & img {
            margin-right: 5px;
        }

        & p {
            height: 100%;
            @include display-flex(row, nowrap, center, center);
        }
    }
}

.other-links {
margin-left: auto;
border-left: 1px solid var(--grey-light);

& a {
    padding: 0 5px;
    margin-left: 10px;
}

& .badge :deep(.v-badge__badge) {
    background-color: var(--red);
    font-size: 0.5em;
    min-width: unset;
    width: 12px;
    height: 12px;
    left: unset !important;
    right: -3px;
}
}

:deep(.v-app-bar-nav-icon) {
margin-left: auto;
}

.avatar-menu {
margin-left: 15px;
border-radius: 0;
box-shadow: none;
cursor: pointer;
display: flex;
align-items: center;
@include userselect;

& .avatar :deep(.v-avatar) {
    border: 1px solid var(--grey-dark);
    margin-right: 0;
}
}

.v-menu .avatar-dropdown {
box-shadow: none !important;
border-radius: 0 !important;
border-left: 1px solid var(--grey-light);
border-bottom: 1px solid var(--grey-light);
padding: 0;
}

.link-wrapper {
text-decoration: none;
color: var(--grey-dark);
@include transition(all, 250ms, ease-in-out);

& .v-list-item {
    @include transition(all, 250ms, ease-in-out);
}

&:hover .v-list-item {
    background-color: var(--grey-light);
    @include transition(all, 250ms, ease-in-out);
}

& i {
    color: var(--grey-dark);
    margin-right: 5px;
}
}

.logo {
width: 64px;
padding-right: 16px;
border-right: 1px solid var(--grey-light);
@include display-flex(row, nowrap, center, center);
}

:deep(.v-navigation-drawer__content) {
width: 100%;
}
</style>
<style lang="scss">
.avatar-dropdown-conent {
    right: 0;
    left: unset !important;
    top: 60px !important;
}

.main-menu-submenu {
    border-top: 2px solid var(--blue);
}

/* !!! VUETIFY OVERRIDE !!! */
.main-menu-submenu .v-list-item--active > .v-list-item__overlay {
    opacity: 0 !important;
}

.main-menu-submenu .v-list-item:hover > .v-list-item__overlay {
    opacity: calc(
        var(--v-hover-opacity) * var(--v-theme-overlay-multiplier)
    ) !important;
}

.main-menu-submenu .v-list-item--active:hover > .v-list-item__overlay {
    opacity: calc(
        (var(--v-activated-opacity) + var(--v-hover-opacity)) *
        var(--v-theme-overlay-multiplier)
    ) !important;
}
</style>
