import { defineStore } from 'pinia';
import axiosInstance from '@/axios';
import type { AxiosResponse } from 'axios';
import { Base64 } from 'js-base64';
import { useLoginStore } from '@/stores/loginStore';

export const useGlobalComponentsStore = defineStore('globalComponentsStore', {
    state: () => {
        return {
            showSnackbar: false as boolean,
            text: '' as string,
            class: '' as string,
            timeout: 0 as number,
            showLoading: false as boolean,
            menuModule: 'all' as string,
            showDialog: false as boolean,
            showConfirmDialog: false as boolean,
            confirmDialogState: {
                item: {} as any,
                item_id: 0 as number,
                title: 'Potvrzení' as string | null,
                showCloseBtn: true as boolean | null,
                action: null as string | null,
                callbackAction: null as any,
                text: null as string | null,
                confirmBtnText: 'Odstranit' as string | null,
                icon: 'mdi-delete' as string | null,
                class: 'danger' as string | null,
                endpoint: null as string | null,
                component: null as string | null,
                name: null as string | null, //TOTO SE POUZIVA CISTE K VRACENI DAT, NIKOLIV K ZOBRAZENI V KOMPONENTE
                return: ['item_id'] as Array<string>,
            },
            onConfirmCallback: null as Function | null,
            showFilterDialog: false as boolean,
            filters: {
                stockItemsArchive: Base64.encode(
                    JSON.stringify([{ f: 'archived_at', o: 'neq', v: null }])
                ) as string | null,
                stockItemRegistrationNumberSeries: null as string | null,
                stockItemStocktakingRegistrationNumberSeries: null as
                    | string
                    | null,
            } as any,
            favouritesPanel: {
                show: false as boolean,
                items: [] as any,
            },
        };
    },
    persist: {
        paths: ['menuModule', 'filters', 'favouritesPanel.items', 'favouritesPanel.show'],
    },
    getters: {
        /*getMenuModule: (state) : string => state.menuModule,*/
        getShowDialog: (state): boolean => state.showDialog,
        getShowConfirmDialog: (state): boolean => state.showConfirmDialog,
        getOnConfirmCallback: (state): Function | null => state.onConfirmCallback,
        getConfirmDialogState: (state): any =>
            state.confirmDialogState,
        getShowFilterDialog: (state): boolean => state.showFilterDialog,
        getFilters: (state): boolean => state.filters,
        getShowFavouritesPanel: (state): boolean => state.favouritesPanel.show,
        getUserFavourites: (state): any => state.favouritesPanel.items,
    },
    actions: {
        async makeApiCalls(
            apiCalls: any,
            getResponse: boolean
        ): Promise<AxiosResponse[] | void> {
            const storeLogin = useLoginStore();
            const promises = apiCalls.map((call: any) => {
                const {
                    method,
                    endpoint,
                    data,
                    whereToStore,
                    whatStore,
                    convertObjectToArray,
                } = call;
                return axiosInstance
                    .request({
                        method,
                        url: endpoint,
                        data,
                        headers: storeLogin.getAuthorizationHeader.headers,
                    })
                    .then((response: AxiosResponse) => {
                        //get, delete?
                        if (!getResponse) {
                            if (convertObjectToArray) {
                                (this as any)[whereToStore] = whatStore
                                    ? Object.keys(response.data[whatStore]).map(
                                        (key) => response.data[whatStore][key]
                                    )
                                    : response.data;
                            } else {
                                (this as any)[whereToStore] = whatStore
                                    ? response.data[whatStore]
                                    : response.data;
                            }
                        }
                        //post, put, patch
                        else {
                            return response;
                        }
                    })
                    .catch((error: any) => {
                        throw error;
                    });
            });

            const responses = await Promise.all(promises);
            if (getResponse) return responses;
        },
        async doApiCall(
            data: any,
            getResponse: boolean,
            returnProperty: string | null
        ): Promise<any> {
            const response = await this.makeApiCalls(data, true);
            if (getResponse && response && response.length > 0) {
                if (returnProperty) return response[0].data[returnProperty];
                else return response[0].data;
            }
        },
        async fetchUserFavourites(): Promise<any> {
            const favourites = await this.doApiCall(
                [
                    {
                        method: 'get',
                        endpoint: '/api/v0/favorites/module', // /module should not be hardcoded here, but it is only possible value of the enum 'favorable_models' now.
                        data: {},
                    },
                ],
                true,
                'items'
            );
            this.favouritesPanel.items = favourites;
            this.toggleShowLoading();
        },
        toggleShowFavouritesPanel(): void {
            this.favouritesPanel.show = !this.favouritesPanel.show;
        },
        toggleShowSnackbar(): void {
            this.computeNotificationTimeout();
            this.showSnackbar = true;
        },
        hideSnackbar(): void {
            this.showSnackbar = false;
        },
        toggleShowLoading(): void {
            this.showLoading = !this.showLoading;
        },
        setShowLoading(value: boolean): void {
            this.showLoading = value;
        },
        toggleShowDialog(): void {
            this.showDialog = !this.showDialog;
        },
        hideDialog(): void {
            this.showDialog = false;
        },
        visibleDialog(): void {
            this.showDialog = true;
        },
        toggleShowConfirmDialog(): void {
            this.showConfirmDialog = !this.showConfirmDialog;
        },
        toggleShowFilterDialog(): void {
            this.showFilterDialog = !this.showFilterDialog;
        },
        computeNotificationTimeout(): void {
            this.timeout = 20000;
        },
        setSnackbarTextAndClass(msg: string, className: string): void {
            //dostal jsem stejny error tim padem nezafunguje watch, ale musim zobrazit chybu
            if (this.text === msg) this.toggleShowSnackbar();

            this.text = msg;
            this.class = className;
        },
        setMenuModule(module: string): void {
            this.menuModule = module;
        },
        setFilters(module: string, filterString: string | null) {
            this.filters[module] = filterString;
        },
        setConfirmDialogState(data: any): void {
            this.confirmDialogState = data;
        },
        setOnConfirmCallback(callback: Function | null): void {
            this.onConfirmCallback = callback;
        },
    },
});
